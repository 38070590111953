<template>
  <div class="review-container" v-loading="loading">
    <el-tabs class="art-tab" v-model="activeTabName" @tab-click="handleTabClick">
      <el-tab-pane label="待评审" name="first"></el-tab-pane>
      <el-tab-pane label="已评审" name="second"></el-tab-pane>
    </el-tabs>
    <template v-if="arts.length > 0">
      <div class="art-card" v-for="art in arts" :key="art.id"
           @click="toArtDetail(art)">
        <el-image class="img-cover"
                  :src="art.coverImg" lazy />
        <div class="right">
          <div class="list-item">{{art.title}}</div>
          <div class="list-item">类别：{{art.cateName}}</div>
          <div class="list-item">创建时间：{{art.createTime}}</div>
          <!--<div><el-tag>{{ART_STATUS_ENUM.getLabelByValue(art.status)}}</el-tag></div>-->
          <div>点击查看详情</div>
        </div>
      </div>
      <el-pagination class="page-block" background
                     @current-change="handleCurrentChange"
                     :current-page="page.curPage"
                     :page-size="page.pageSize"
                     layout="total,prev,pager,next"
                     :total="total"></el-pagination>
    </template>
    <div v-else class="empty-block">
      暂无数据
    </div>
  </div>
</template>

<script>
  import {reviewList} from "@/page-home/api/expert";

  export default {
    name: "ExpertReviewPage",
    data() {
      return {
        loading: false,
        page: {
          curPage: 1,
          pageSize: 30
        },
        total: 0,
        arts: [],
        activeTabName: 'first'
      }
    },

    methods: {
      getReviewList(listType) {
        if (this.loading) return
        this.loading = true
        const payload = Object.assign({}, this.page)
        payload.listType = listType ? listType : 1
        reviewList(payload).then(res => {
          this.loading = false
          this.arts = res.data.records || []
          this.total = parseInt(res.data.total)
        }).catch(err => {
          this.loading = false
          this.$message.error(err.msg)
        })
      },

      handleCurrentChange() {

      },

      handleTabClick(item) {
        const tab = parseInt(item.index) + 1
        if (tab === this.curTab) return
        this.curTab = tab
        this.getReviewList(tab)
      },

      toArtDetail(item) {
        this.$router.push(`/expert/review/detail?id=${item.artId}&v=${item.version}`)
      }
    },

    created() {
      this.getReviewList()
    }
  }
</script>

<style lang="scss" scoped>
  .review-container {
    padding: 20px;
  }
  .art-tab {
    margin-top: 20px;
    v::deep {
      .el-tabs__nav {
        margin-left: 40px;
      }
      .el-tabs__item {
        width: 180px;
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        text-align: center;
      }
      .el-tabs__item.is-active {
        color: #000000;
      }
      .el-tabs__active-bar {
        height: 3px;
        background-color: #004DF8;
      }
    }
  }
  .art-card {
    display: flex;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #a1a1a1;
    border-radius: 12px;
    cursor: pointer;
    .img-cover {
      width: 150px;
      height: 150px;
      margin-right: 20px;
    }
    .right {
      margin-left: 40px;
      .list-item {
        margin-bottom: 6px;
      }
    }
  }

  .empty-block {
    margin-top: 100px;
    text-align: center;
  }
</style>
